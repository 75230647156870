.slide {
  position: relative;
  margin-right: 0.75rem;
  cursor: pointer;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(36, 62, 99, 0.639);
  width: 50px;
  height: 50px;
  font-size: 40px;
}
.slick-prev, .slick-next {
  z-index: 11;
}
.slick-next {
  right: 6%;
  transform: translateY(-50%);
  color: #ffffff14;
}

.slick-prev {
  left: 6%;
  transform: translateY(-50%);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(235, 204, 153, 0) 0%, rgba(125, 93, 55, 0.8) 100%);
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  z-index: -1;
  margin-right: 0.75rem
}

.slide:hover .overlay {
  opacity: 1;
  z-index: 1;
  margin-right: 0.75rem
}

.overlay h2 {
  font-size: 2rem;
  margin: 0;
  color: white;
}

.overlay p {
  font-size: 1.2rem;
  margin: 0;
  color: white;
}

  
.team-card-image-container {
  width: 300px; /* ancho fijo para el contenedor de la imagen */
  height: 200px; /* altura fija para la imagen */
  overflow: hidden; /* oculta cualquier contenido que sobresalga del contenedor */
}

.team-card-image {
  width: 100%; /* establece el ancho de la imagen al 100% del contenedor */
  height: 100%; /* establece la altura de la imagen al 100% del contenedor */
  object-fit: cover; /* ajusta la imagen para que ocupe todo el contenedor y mantenga la relación de aspecto */
}